import {
  cloneDeep,
  filter,
  find,
  identity,
  map,
  uniq,
  values,
  reduce,
  flatMap,
  includes,
  uniqBy,
  orderBy,
  isNil,
} from 'lodash';
import { findSportById, findAllCompetitionsByIds } from '@/services/api';
import isUuid from '@/utils/is-uuid';

export const filterTypes = {
  EVENT_ID: 'EVENT_ID',
  SPORT_AND_COMPETITION: 'SPORT_AND_COMPETITION',
  ODDS: 'ODDS',
  STAKE: 'STAKE',
  POTENTIAL_PAYOUT: 'POTENTIAL_PAYOUT',
};

export const dataTypes = {
  NUMBER: 'NUMBER',
  PERCENTAGE: 'PERCENTAGE',
  UUID: 'UUID',
  SPORT_AND_COMPETITION: 'SPORT_AND_COMPETITION',
};

export const conditionTypes = {
  LESS_THAN: 'LESS_THAN',
  LESS_OR_EQUAL: 'LESS_OR_EQUAL',
  EQUAL: 'EQUAL',
  GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
  GREATER_THAN: 'GREATER_THAN',
};

const filterDefs = {
  [filterTypes.EVENT_ID]: {
    id: filterTypes.EVENT_ID,
    type: dataTypes.UUID,
    label: 'Event ID',
    keys: ['eventId'],
  },
  [filterTypes.SPORT_AND_COMPETITION]: {
    id: filterTypes.SPORT_AND_COMPETITION,
    type: dataTypes.SPORT_AND_COMPETITION,
    label: 'Sport & Competition',
    keys: ['sport', 'competition'],
    hideLabel: true,
  },
  [filterTypes.ODDS]: {
    id: filterTypes.ODDS,
    type: dataTypes.NUMBER,
    label: 'Odds',
    keys: ['odds'],
  },
  [filterTypes.STAKE]: {
    id: filterTypes.STAKE,
    type: dataTypes.PERCENTAGE,
    label: 'Stake',
    keys: ['stake'],
  },
  [filterTypes.POTENTIAL_PAYOUT]: {
    id: filterTypes.POTENTIAL_PAYOUT,
    type: dataTypes.PERCENTAGE,
    label: 'Potential Payout',
    keys: ['potentialPayout'],
  },
};

export const getFilterDef = (id) => {
  const foundFilterDef = filterDefs[id];
  if (!foundFilterDef) return null;
  return cloneDeep(foundFilterDef);
};

export const getFilterOptions = () => orderBy(cloneDeep(values(filterDefs)), 'label');

const filterValidators = {
  [filterTypes.EVENT_ID](parameter) {
    return (!parameter?.length || isUuid(parameter)) ? '' : 'Event ID must be a valid UUID.';
  },
};

export const getFilterError = (filterItem) => {
  const validator = filterValidators[filterItem.id];
  if (!validator) return '';
  return validator(filterItem.parameter);
};

const conditionTypeDefs = {
  [conditionTypes.EQUAL]: {
    id: conditionTypes.EQUAL,
    label: 'Equals (=)',
    shortLabel: 'Equals',
  },
  [conditionTypes.LESS_THAN]: {
    id: conditionTypes.LESS_THAN,
    label: 'Less than (<)',
    shortLabel: 'Less than',
  },
  [conditionTypes.LESS_OR_EQUAL]: {
    id: conditionTypes.LESS_OR_EQUAL,
    label: 'Less than or equal to (<=)',
    shortLabel: 'Less than or equal to',
  },
  [conditionTypes.GREATER_THAN]: {
    id: conditionTypes.GREATER_THAN,
    label: 'Greater than (>)',
    shortLabel: 'Greater than',
  },
  [conditionTypes.GREATER_OR_EQUAL]: {
    id: conditionTypes.GREATER_OR_EQUAL,
    label: 'Greater than or equal to (>=)',
    shortLabel: 'Greater than or equal to',
  },
};

export const getConditionTypeOptions = () => cloneDeep(values(conditionTypeDefs));

const filterParsers = {
  [filterTypes.EVENT_ID](allFilters) {
    const eventIdFilter = find(allFilters, { type: 'eventId' });

    const definition = filterDefs[filterTypes.EVENT_ID];
    const conditionType = conditionTypeDefs[eventIdFilter?.conditionType] || null;
    const parameter = eventIdFilter?.parameter || null;

    return {
      ...definition,
      conditionType,
      parameter,
    };
  },
  async [filterTypes.SPORT_AND_COMPETITION](allFilters) {
    const sportFilter = find(allFilters, { type: 'sport' });
    const sportFilterObject = await findSportById(sportFilter.parameter);
    const competitionFilter = filter(allFilters, { type: 'competition' });
    const competitionFilterIds = map(competitionFilter, 'parameter');
    const competitionFilterObjects = await findAllCompetitionsByIds(competitionFilterIds);

    const definition = filterDefs[filterTypes.SPORT_AND_COMPETITION];
    const conditionType = conditionTypeDefs[conditionTypes.EQUAL];
    const parameter = {
      sport: sportFilterObject,
      competitions: competitionFilterObjects,
    };

    return {
      ...definition,
      conditionType,
      parameter,
    };
  },
  [filterTypes.ODDS](allFilters, { selectedOddFormat }) {
    const oddsFilter = find(allFilters, { type: 'odds' });

    let odd = oddsFilter.parameter?.decimalPrice || 0;
    if (selectedOddFormat === 'american') {
      const originalPrice = oddsFilter.parameter?.originalFormattedPrice || {};
      odd = originalPrice?.signIsPlus ? originalPrice?.value || 0 : -Math.abs(originalPrice?.value || 0);
    }
    if (selectedOddFormat === 'probability') {
      odd = oddsFilter.parameter?.probability || 0;
    }

    return {
      ...filterDefs[filterTypes.ODDS],
      conditionType: conditionTypeDefs[oddsFilter.conditionType],
      parameter: odd,
    };
  },
  [filterTypes.STAKE](allFilters) {
    const stakeFilter = find(allFilters, { type: 'stake' });
    return {
      ...filterDefs[filterTypes.STAKE],
      conditionType: conditionTypeDefs[stakeFilter.conditionType] || null,
      parameter: isNil(stakeFilter.parameter) ? 0 : stakeFilter.parameter / 100,
    };
  },
  [filterTypes.POTENTIAL_PAYOUT](allFilters) {
    const potentialPayoutFilter = find(allFilters, { type: 'potentialPayout' });
    return {
      ...filterDefs[filterTypes.POTENTIAL_PAYOUT],
      conditionType: conditionTypeDefs[potentialPayoutFilter.conditionType] || null,
      parameter: isNil(potentialPayoutFilter.parameter) ? 0 : potentialPayoutFilter.parameter / 100,
    };
  },
};

export const parseFilter = async (filterDef, allFilters, context = {}) => {
  const parser = filterParsers[filterDef.id];

  if (!parser) {
    console.warn('Parsing unsupported filter type:', filterDef.id);
    return null;
  }

  return parser(allFilters, context);
};

export const parseAllFilters = async (allFilters, context = {}) => {
  const allFilterTypes = uniq(map(allFilters, 'type'));
  const mappedFilterDefs = uniqBy(reduce(allFilterTypes, (result, filterType) => {
    const foundFilterDef = find(filterDefs, (def) => includes(def.keys, filterType));
    if (!foundFilterDef) return result;
    return [...result, foundFilterDef];
  }, []), 'id');
  const parsedFiltersRequests = map(mappedFilterDefs, (filterDef) => parseFilter(filterDef, allFilters, context));
  const parsedFilters = await Promise.all(parsedFiltersRequests);
  return filter(parsedFilters, identity);
};

const filterComposers = {
  [filterTypes.EVENT_ID](parsedFilter) {
    const parameter = parsedFilter.parameter || '';

    return [{
      type: 'eventId',
      conditionType: conditionTypes.EQUAL,
      parameter,
    }];
  },
  [filterTypes.SPORT_AND_COMPETITION](parsedFilter) {
    const sportFilter = {
      type: 'sport',
      conditionType: conditionTypes.EQUAL,
      parameter: parsedFilter.parameter?.sport?.sportId || '',
    };
    const conditionFilters = map(parsedFilter.parameter?.competitions || [], ({ competitionId }) => ({
      type: 'competition',
      conditionType: conditionTypes.EQUAL,
      parameter: competitionId,
    }));

    return [
      sportFilter,
      ...conditionFilters,
    ];
  },
  [filterTypes.ODDS](parsedFilter, { selectedOddFormat }) {
    let formattedParameter = {};
    if (selectedOddFormat === 'american') {
      formattedParameter = {
        originalFormattedPrice: {
          signIsPlus: parsedFilter.parameter >= 0,
          value: Math.abs(parsedFilter.parameter),
          priceType: 'american',
        },
        format: 'ORIGINAL_FORMATTED_PRICE',
      };
    }
    if (selectedOddFormat === 'decimal') {
      formattedParameter = {
        decimalPrice: parsedFilter.parameter,
        format: 'DECIMAL_PRICE',
      };
    }
    if (selectedOddFormat === 'probability') {
      formattedParameter = {
        probability: parseFloat(parsedFilter.parameter / 100),
        format: 'PROBABILITY',
      };
    }

    return {
      type: 'odds',
      conditionType: parsedFilter.conditionType?.id || '',
      parameter: formattedParameter,
    };
  },
  [filterTypes.STAKE](parsedFilter) {
    return {
      type: 'stake',
      conditionType: parsedFilter.conditionType?.id || '',
      parameter: isNil(parsedFilter.parameter) ? 0 : parsedFilter.parameter * 100,
    };
  },
  [filterTypes.POTENTIAL_PAYOUT](parsedFilter) {
    return {
      type: 'potentialPayout',
      conditionType: parsedFilter.conditionType?.id,
      parameter: isNil(parsedFilter.parameter) ? 0 : parsedFilter.parameter * 100,
    };
  },
};

export const composeFilter = (parsedFilter, context = {}) => {
  const parser = filterComposers[parsedFilter.id];

  if (!parser) {
    console.warn('Composing unsupported filter type:', parsedFilter.id);
    return null;
  }

  return parser(parsedFilter, context);
};

export const composeAllFilters = (allParsedFilters, context = {}) => flatMap(allParsedFilters, (filterItem) => composeFilter(filterItem, context));
